const intro = new Audio(new URL('./game/sounds/intro.mp3', import.meta.url));
const countDownSound = new Audio(new URL('./game/sounds/count.mp3', import.meta.url));
countDownSound.volume = 0.35;
const buttonPress = new Audio(new URL('./game/sounds/button.mp3', import.meta.url));
buttonPress.volume = 0.35;
const collect = new Audio(new URL('./game/sounds/collect.mp3', import.meta.url));
collect.volume = 0.35;
const collectSpecial = new Audio(new URL('./game/sounds/collectspecial.mp3', import.meta.url));
collectSpecial.volume = 0.35;
const walk = new Audio(new URL('./game/sounds/walk.mp3', import.meta.url));
walk.volume = 0.35;
walk.loop = true;
const walkfast = new Audio(new URL('./game/sounds/walkfast.mp3', import.meta.url));
walkfast.volume = 0.35;
walkfast.loop = true;
const walkslow = new Audio(new URL('./game/sounds/walkslow.mp3', import.meta.url));
walkslow.volume = 0.35;
walkslow.loop = true;
const smoosh = new Audio(new URL('./game/sounds/end.mp3', import.meta.url));
smoosh.volume = 0.35;
const doublepoints = new Audio(new URL('./game/sounds/doublepoints.mp3', import.meta.url));
doublepoints.volume = 0.35;
const speedup = new Audio(new URL('./game/sounds/speedup.mp3', import.meta.url));
speedup.volume = 0.35;
const speeddown = new Audio(new URL('./game/sounds/speeddown.mp3', import.meta.url));
speeddown.volume = 0.5;
const jump = new Audio(new URL('./game/sounds/jump.mp3', import.meta.url));
jump.volume = 0.45;
const amber = new Audio(new URL('./game/sounds/amber.mp3', import.meta.url));
amber.volume = 0.75;
const sleighBellsSound = new Audio(new URL('./game/sounds/sleighbells.mp3', import.meta.url));
sleighBellsSound.volume = 0.2;
const ground = new Audio(new URL('./game/sounds/ground.mp3', import.meta.url));
ground.volume = 0.45;
const brains = new Audio(new URL('./game/sounds/brains.mp3', import.meta.url));
brains.volume = 0.75;
const Parse = require('parse');
var Player = Parse.Object.extend("Player");
const SERVER_URL = 'https://funsizeapps.b4a.app/';
const APP_ID = 'rHwkgreMFdjMqUGu9EoemDE4YMomVNLQwIX9Czr9';
const JAVASCRIPT_KEY = 'xdllsMsa2dAhWFuovVzyJTj7OIZmzLMeo5vsEFYv';
Parse.initialize(APP_ID, JAVASCRIPT_KEY);
Parse.serverURL = SERVER_URL;

const random = (min, max, makeInt = true) => {
    const val = Math.random() * (max - min) + min;
    return (makeInt ? Math.floor(val) : val);
};

const areColliding = (iBB, gBB) => {
    const fudge = 35;
    return (iBB.left + fudge < gBB.left + gBB.width &&
        iBB.left + iBB.width > gBB.left + fudge &&
        iBB.top + fudge < gBB.top + gBB.height &&
        iBB.height + iBB.top > gBB.top + fudge);
};

const getFibNum = (num) => {
    var x = 3;
    var y = 5;
    while (num > x + y) {
        const temp = y;
        y = x + y;
        x = temp;
    }
    return y;
}

const walkBuffDuration = 2300;
const pointBuffDuration = 2000;
const buffProbability = 0.2;
const speedBuffProbability = 0.65;
const fallSpeed = { min: 1.5, max: 15 };
const hFallSpeed = { min: 1.5, max: 4 };
const itemDropWait = { min: 25, max: 175 };
const walkSpeeds = { reg: 2, fast: 1.2, slow: 8 };
const reindeerDropProbabilityThreshold = 50;
const baseReindeerDropProbability = 0.25;
const maxReindeerDropProbability = 0.95;
const treeDropWait = { min: 35, max: 200 };
const fibFloor = 50;

var sleigh, specialItemInterval, grandma, grandmaSmoosh, grandmaSmooshWidth, grandmaLegs, grandmaBag, grandmaWidth, board, boardWidth, boardLeft, boardBottom, playerInfo, gameCountDown, walkInterval, gBB, currentWalkBuffDuration, currentPointBuffDuration;
var gBgF, gBgM, gBgB, gBgS, goBgF, goBgM, goBgB, goBgS;
var items = [];
var currentItemDropWait = 0;
var currentTreeDropWait = 0;
var maxItemDropWait = 0;
var maxTreeDropWait = 0;
var reindeerDropProbability = baseReindeerDropProbability;
var snowStep = 0;
var maxSnowStep = 1;
var smStep = 0;
var maxSMStep = 5;
var currentScore = 0;
var grandMaSmooshes = 0;
var walkStep = 0;
var maxWalkSteps = 2;
var walkReady = false;
var firstTime = true;
var gameReady = false;
var houseFrame = 0;
var houseFrameCount = 4;
var currentPointValue = 1;
var currentWalkSpeed = walkSpeeds.reg;
var currentWalkSound = walk;
var isHundoStart = false;
var flipFrame = 0;
var flipPressie = 0;
var animatingSleigh = true;
var sleighSpeed = 2;
var zombieMode = false;

window.addEventListener('load', () => {
    if (document.querySelector('.g-house')) {
        setInterval(() => {
            houseFrame = (houseFrame + 1 >= houseFrameCount ? 0 : houseFrame + 1);
            document.querySelector('.gh0').style.display = houseFrame == 0 ? 'flex' : 'none';
            document.querySelector('.gh1').style.display = (houseFrame == 1 || houseFrame == 3) ? 'flex' : 'none';
            document.querySelector('.gh2').style.display = houseFrame == 2 ? 'flex' : 'none';
        }, 600);
        sleigh = document.querySelector('.sleigh');
        if (animatingSleigh) { window.setTimeout(animateSleigh, 150); }
        setInterval(() => {
            flipFrame = (flipFrame == 1 ? 0 : 1);
            document.querySelector('.santa0').style.display = flipFrame == 0 ? 'block' : 'none';
            document.querySelector('.santa1').style.display = flipFrame == 1 ? 'block' : 'none';
            document.querySelector('.grandmas0').style.display = flipFrame == 0 ? 'block' : 'none';
            document.querySelector('.grandmas1').style.display = flipFrame == 1 ? 'block' : 'none';
        }, 250);
        document.querySelector('.takeover-overlay').addEventListener('click', () => {
            gsap.fromTo(document.querySelector('.content'), { scale: 0.35 }, { scale: 1, ease: 'power2.easeInOut', duration: 2.5, delay: 0.55 });
            gsap.to(document.querySelector('.content'), { opacity: 1, duration: 0.25, delay: 0.55 });
            gsap.to(document.querySelector('nav'), { opacity: 1, ease: 'power2.easeInOut', duration: 2.5, delay: 0.55 });
            gsap.to(document.querySelector('.takeover-overlay'), {
                scale: 17, ease: 'power2.easeInOut', duration: 2, delay: 0.5, onComplete: () => {
                    document.querySelector('.takeover-overlay').style.display = 'none';
                    board.style.pointerEvents = 'all';
                    walkReady = true;
                }
            });
            document.querySelector('.takeover-overlay').classList.add('dead');
            gsap.to(document.querySelector('.gd0'), { rotationY: 95, duration: 1, ease: Power2.easeInOut })
            gsap.to(document.querySelector('.gd1'), { rotationY: 95, duration: 1, ease: Power2.easeInOut })
            gsap.to(document.querySelector('.gd2'), { rotationY: 95, duration: 1, ease: Power2.easeInOut })
            intro.play();
            sleighSpeed = 4;
            sleigh.style.width = '300px';
            document.querySelector('.g-bg-container').insertBefore(sleigh, document.querySelector('.g-bg-m'));
        });
    }
    else {
        document.querySelector('.content').style.opacity = 1;
        document.querySelector('nav').style.opacity = 1;
    }
    if (document.querySelector('.grandma')) {
        grandma = document.querySelector('.grandma');
        grandmaSmoosh = document.querySelector('.grandmas');
        grandmaSmooshWidth = grandmaSmoosh.getBoundingClientRect().width;
        grandmaLegs = document.querySelector('.grandma-legs');
        grandmaBag = document.querySelector('.grandma-bag');
        grandmaWidth = grandma.getBoundingClientRect().width;
        board = document.querySelector('.grandma-game');
        boardBottom = board.getBoundingClientRect().bottom;
        boardWidth = board.getBoundingClientRect().width;
        boardLeft = board.getBoundingClientRect().left;
        gBgF = document.querySelector('.g-bg-f');
        gBgM = document.querySelector('.g-bg-m');
        gBgB = document.querySelector('.g-bg-b');
        gBgS = document.querySelector('.g-bg-s');
        goBgF = document.querySelector('.go-bg-f');
        goBgM = document.querySelector('.go-bg-m');
        goBgB = document.querySelector('.go-bg-b');
        goBgS = document.querySelector('.go-bg-s');
        setInterval(() => {
            gBgS.style.transform = flipFrame == 0 ? 'scaleX(-1)' : 'scaleX(1)';
            goBgS.style.transform = flipFrame == 0 ? 'scaleX(-1)' : 'scaleX(1)';
        }, 350);
        document.querySelector('#btn-play-hundo').addEventListener('click', () => {
            buttonPress.play();
            setTimeout(() => {
                handleGameStart(true);
            }, 600);
        });
        document.querySelector('#btn-replay-hundo').addEventListener('click', () => {
            buttonPress.play();
            setTimeout(() => {
                handleGameRestart(true);
            }, 600);
        });
        document.querySelector('#btn-play').addEventListener('click', () => {
            buttonPress.play();
            setTimeout(() => {
                handleGameStart();
            }, 600);
        });
        document.querySelector('#btn-replay').addEventListener('click', () => {
            buttonPress.play();
            setTimeout(() => {
                handleGameRestart();
            }, 600);
        });
        document.querySelector('#btn-hide').addEventListener('click', () => {
            board.style.display = 'none';
            board.style.pointerEvents = 'none';
        });
        updateUI(true);
        window.requestAnimationFrame(grandmaTick);
    }
    if (document.querySelector('.snow0')) {
        setInterval(() => {
            var oldStep = snowStep;
            if (snowStep >= maxSnowStep) { snowStep = 0; }
            else { snowStep++; }
            gsap.to(document.querySelector(`.snow${oldStep}`), { opacity: 0, duration: 0.2 });
            gsap.to(document.querySelector(`.snow${snowStep}`), { opacity: 1, duration: 0.2 });
        }, 1200);
        setInterval(() => {
            if (smStep > maxSMStep) {
                for (var i = 0; i <= maxSMStep; i++) {
                    gsap.to(document.querySelector(`.sm${i}`), { opacity: 0, duration: 0.2 });
                }
                smStep = 0;
            }
            else {
                gsap.to(document.querySelector(`.sm${smStep}`), { opacity: 1, duration: 0.5 });
                smStep++;
            }
        }, 2000);
    }

    // document.querySelector('.takeover-overlay').style.display = 'none';
    // document.querySelector('.content').style.opacity = 1;
    // document.querySelector('.g-bg-container').style.display = 'none';
    // document.querySelector('.go-bg-container').style.display = 'block';
    // document.querySelector('.grandma-container').style.display = 'none';
    // initGrave();
});

const animateSleigh = () => {
    const reverse = Math.random() < 0.5;
    const sW = sleigh.getBoundingClientRect().width;
    const yLoc1 = random(50, 250, true);
    const yLoc2 = random(50, 250, true);
    document.querySelector('.santa0').style.transform = `scaleX(${reverse ? -1 : 1})`;
    document.querySelector('.santa1').style.transform = `scaleX(${reverse ? -1 : 1})`;
    if(Math.random() < 0.3) { sleighBellsSound.play(); }
    gsap.fromTo(sleigh, { y: yLoc1 }, { y: yLoc2, duration: 0.35, yoyo: true, repeat: -1, ease: 'power1.inOut' });
    gsap.fromTo(sleigh, { x: (reverse ? document.documentElement.clientWidth : -sW) }, {
        x: (reverse ? -sW : document.documentElement.clientWidth), duration: sleighSpeed, ease: 'linear', onComplete: () => {
            const r = random(750, 3000, true);
            if (animatingSleigh) { window.setTimeout(animateSleigh, r); }
        }
    });
}

const updateUI = () => {
    document.body.style.pointerEvents = 'none';
    getHighScore().then(() => {
        document.body.style.pointerEvents = 'all';
        initHundoButtons();
    });
    getLeaderBoard();
};

window.addEventListener('resize', () => {
    if (!board) { return; }
    boardBottom = board.getBoundingClientRect().bottom;
    boardWidth = board.getBoundingClientRect().width;
    boardLeft = board.getBoundingClientRect().left;
});

async function getHighScore() {
    const query = new Parse.Query('Player');
    const funsizer = Parse.User.current();
    query.equalTo('Email', funsizer.get('email'));
    query.select('grandma', 'Name');
    try {
        var p = await query.find();
        p = p[0];
        playerInfo = { id: p.id, hs: p.get('grandma')[0], gro:p.get('grandma')[1], n: p.get('Name') };
        document.getElementById('cHS').innerHTML = playerInfo.hs;
        grandMaSmooshes = playerInfo.gro;
        return
    } catch (error) {
        throw (error);
    }
}

async function updateHighScore() {
    if (currentScore > playerInfo.hs) { playerInfo.hs = currentScore; }
    document.getElementById('cHS').innerHTML = playerInfo.hs;
    document.getElementById('grave-score-you').innerHTML = currentScore;
    document.getElementById('grave-high-you').innerHTML = playerInfo.hs;
    playerInfo.gro = grandMaSmooshes;
    var p = new Player();
    p.set("objectId", playerInfo.id);
    p.set("grandma", [playerInfo.hs, playerInfo.gro]);
    p.save().then(() => {
        getLeaderBoard();
        initHundoButtons();
    }).catch(function (e) {
        console.log(`Error updating high score : ${e.code} - ${e.message}`);
    });
}

async function getLeaderBoard() {
    var runOvers = 0;
    var leaders = [];
    const query = new Parse.Query('Player');
    query.equalTo('Active', true);
    query.select('grandma', 'Name');
    try {
        var lb = await query.find();
        lb.forEach((l) => {
            const g = l.get('grandma');
            if(g.length > 0) {
                const s = parseInt(g[0]);
                if(s > 0) {
                    leaders.push({name: l.get('Name').split(' ')[0], score: s});
                }
                runOvers += parseInt(g[1]);
            }
        });
        leaders = leaders.sort((a,b)=>{ return b.score - a.score; });
        document.querySelector('#grave-score-grandma').innerHTML = runOvers;
        document.querySelector('#grave-score-leaders').innerHTML = '';
        if(leaders.length == 0) { document.querySelector('#lHs').innerHTML = 'Nobody'; }
        else {
            for(var i = 0; i < 5 && i < leaders.length; i++) {
                const e = document.createElement('div');
                e.classList.add('leader-score');
                e.innerHTML = `${leaders[i].name} - ${leaders[i].score}`;
                document.querySelector('#grave-score-leaders').appendChild(e);
                if(i == 0) { document.querySelector('#lHs').innerHTML = e.innerHTML; }
            }
        }
        return
    } catch (error) {
        throw (error);
    }
}

var zombieTL;
const initGrave = () => {
    document.querySelector('.zombie-container').style.opacity = 1;
    ground.play();
    window.setTimeout(()=> {
        brains.play();
    }, 750);
    if(!zombieTL){
        zombieTL = gsap.timeline();
        zombieTL.to(document.querySelector('.zombie-mound'), {y: -65, duration: 0.75}, 0);
        zombieTL.fromTo(document.querySelector('.zombie-mound'), {x: -15}, {x: 15, duration: 0.1, yoyo: true, repeat:5}, 0);
        zombieTL.to(document.querySelector('.zombie-hand'), {y: -330, duration: 0.75, delay: 0.65}, 0);
        zombieTL.to(document.querySelector('.zombie-mound'), {x: 0, duration: 0.25, dealy: 0.5}, 0);
        zombieTL.to(document.querySelector('.zombie-hand'), {y: -300, duration: 0.9, yoyo: true, repeat:-1, delay: 1}, 1);
    }
    else {
        zombieTL.play();
    }
}

const initHundoButtons = () => {
    if (playerInfo.hs >= fibFloor) {
        const fibNum = getFibNum(playerInfo.hs);
        document.querySelector('#btn-play-hundo').style.display = 'inline';
        document.querySelector('#btn-play-hundo-val').innerHTML = fibNum;
        document.querySelector('#btn-replay-hundo').style.display = 'inline';
        document.querySelector('#btn-replay-hundo-val').innerHTML = fibNum;
    }
    else {
        document.querySelector('#btn-play-hundo').style.display = 'none';
        document.querySelector('#btn-replay-hundo').style.display = 'none';
    }
}

var leftPressed = false;
var rightPressed = false;
var shouldGoLeft = false;
var shouldGoRight = false;
var shouldStop = false;
var jumping = false;
var anim, animLeg, animBag;
window.addEventListener('keydown', (e) => {
    if (!grandma) { return; }
    if (!walkReady) { return; }
    if((e.key == " " || e.key == "ArrowUp") && !jumping) { goAheadAndJump(); }
    if (e.key != 'ArrowRight' && e.key != 'ArrowLeft') { return; }
    if (e.key == 'ArrowRight') {
        if (!rightPressed) {
            shouldGoRight = true;
            document.querySelector('.grandmal').style.opacity = 0;
            document.querySelector('.grandmar').style.opacity = 1;
            document.querySelector('.grandmac').style.opacity = 0;
            grandmaBag.style.left = '2px';
            stopGrandmaWalk();
            walkGrandma();
        }
        rightPressed = true;
    }
    else if (e.key == 'ArrowLeft') {
        if (!leftPressed) {
            shouldGoLeft = true;
            document.querySelector('.grandmal').style.opacity = 1;
            document.querySelector('.grandmar').style.opacity = 0;
            document.querySelector('.grandmac').style.opacity = 0;
            grandmaBag.style.left = '-5px';
            stopGrandmaWalk();
            walkGrandma();
        }
        leftPressed = true;
    }
});

window.addEventListener('keyup', (e) => {
    if (!grandma) { return; }
    if (e.key == 'ArrowRight') {
        rightPressed = false;
        if (!leftPressed) { shouldStop = true; }
        else {
            shouldGoLeft = true;
            document.querySelector('.grandmal').style.opacity = 1;
            document.querySelector('.grandmar').style.opacity = 0;
            document.querySelector('.grandmac').style.opacity = 0;
            grandmaBag.style.left = '-5px';
        }
    }
    else if (e.key == 'ArrowLeft') {
        leftPressed = false;
        if (!rightPressed) { shouldStop = true; }
        else {
            shouldGoRight = true;
            document.querySelector('.grandmal').style.opacity = 0;
            document.querySelector('.grandmar').style.opacity = 1;
            document.querySelector('.grandmac').style.opacity = 0;
            grandmaBag.style.left = '2px';
        }
    }
    if (!rightPressed && !leftPressed) {
        shouldStop = true;
        document.querySelector('.grandmal').style.opacity = 0;
        document.querySelector('.grandmar').style.opacity = 0;
        document.querySelector('.grandmac').style.opacity = 1;
        grandmaBag.style.left = '0';
        stopGrandmaWalk();
    }
});

var amberAnim;
const handleAmber = () => {
    amber.play();
    gsap.to(document.querySelector('.amber'), { opacity: 1, duration: 0.25 });
    amberAnim = gsap.to(document.querySelector('.amber-1'), { opacity: 0, duration: 0.75, repeat: -1, yoyo: true });
    setTimeout(() => {
        amberAnim.kill();
        gsap.to(document.querySelector('.amber'), { opacity: 0, duration: 0.25 });
    }, 5500);
}

const updateScore = (isReset = false, isSpecial = false) => {
    if (isReset) {
        if (isHundoStart) { currentScore = getFibNum(playerInfo.hs); }
        else { currentScore = 0; }
    }
    else {
        currentScore += currentPointValue * (isSpecial ? 5 : 1);
        if (currentScore == 311) { handleAmber(); }
        if (reindeerDropProbability < maxReindeerDropProbability) {
            const floorVal = Math.floor(currentScore / reindeerDropProbabilityThreshold) - 1;
            const probMod = floorVal / 10;
            reindeerDropProbability = Math.min(Math.max(baseReindeerDropProbability, baseReindeerDropProbability + probMod), maxReindeerDropProbability);
        }
    }
    document.getElementById('cS').innerHTML = currentScore;
}

const handleShouldDropItem = () => {
    if (Math.random() < 0.98) {
        const item = document.createElement('div');
        item.id = 'item' + items.length;
        item.classList.add('pressie');
        const pType = random(1, 12);
        if (Math.random() < 0.25) {
            item.classList.add('special-pressie');
            var p = document.createElement('div');
            p.classList.add(`p${pType}`);
            item.appendChild(p);
            p = document.createElement('div');
            p.classList.add(`p${pType}-glow`);
            item.appendChild(p);
        }
        else {
            item.classList.add(`p${pType}`);
        }
        dropItem(item);
    }
    handleShouldDropSpecialItem();
};

var reindeerFrame = 0;
const handleShouldDropReindeer = () => {
    if (Math.random() < reindeerDropProbability) {
        const item = document.createElement('div');
        item.classList.add('reindeer');
        var rd = document.createElement('div');
        rd.classList.add('reindeer0');
        item.appendChild(rd);
        rd = document.createElement('div');
        rd.classList.add('reindeer1');
        item.appendChild(rd);
        rd = document.createElement('div');
        rd.classList.add('reindeer2');
        item.appendChild(rd);
        item.id = 'item' + items.length;
        reindeerFrame = 0;
        dropItem(item);
    }
}

const handleShouldRunReindeer = () => {
    if (Math.random() < reindeerDropProbability) {
        const item = document.createElement('div');
        item.classList.add('reindeer-r');
        var rd = document.createElement('div');
        rd.classList.add('reindeer-r0');
        item.appendChild(rd);
        rd = document.createElement('div');
        rd.classList.add('reindeer-r1');
        item.appendChild(rd);
        item.id = 'item' + items.length;
        dropItem(item, true);
    }
}

const handleShouldDropSpecialItem = () => {
    if (Math.random() < buffProbability) {
        const item = document.createElement('div');
        item.classList.add('buff-item');
        if (Math.random() < speedBuffProbability) {
            if (Math.random() < 0.5) {
                item.classList.add('buff-fast');
            }
            else {
                item.classList.add('buff-slow');
            }
            dropItem(item);
        }
        else {
            if (currentPointValue != 1) { return; }
            item.classList.add('buff-double');
            dropItem(item);
        }
    }
};

const dropItem = (item, horizontal = false) => {
    board.appendChild(item);
    const itemWidth = parseFloat(window.getComputedStyle(item, null).getPropertyValue('width').replace('px', ''));
    const itemHeight = parseFloat(window.getComputedStyle(item, null).getPropertyValue('height').replace('px', ''));
    const reverse = Math.random() < 0.5;
    if(horizontal) {
        item.style.bottom = `-${itemHeight * 0.7}px`;
        item.style.left = reverse ? `${boardWidth}px` : `${-itemWidth}px`;
        if(reverse){
            item.children[0].style.transform = 'scaleX(-1)';
            item.children[1].style.transform = 'scaleX(-1)';
        }
    }
    else {
        item.style.top = `-${itemHeight}px`;
        item.style.left = `${random(-itemWidth * 0.5, boardWidth - (itemWidth * 0.5) + boardLeft)}px`;
    }
    items[items.length] = { htmlItem: item, width: itemWidth, height: itemHeight, fallSpeed: (horizontal ? random(hFallSpeed.min, hFallSpeed.max) : random(fallSpeed.min, fallSpeed.max)), h: horizontal, r: reverse};
};

const removeItem = (p) => {
    p.htmlItem.remove();
    const index = items.indexOf(p);
    if (index > -1) {
        items.splice(index, 1);
    }
};

const handleGameOver = () => {
    clearBuffs();
    document.querySelector('.zombie-container').style.opacity = 0;
    grandMaSmooshes++;
    zombieMode = ((grandMaSmooshes % 3 == 0) && (Math.random() < 0.75));
    if(zombieMode) { initGrave(); }
    else { smoosh.play(); }
    grandmaSmoosh.style.opacity = 1;
    grandma.style.opacity = 0;
    grandmaLegs.style.opacity = 0;
    grandmaBag.style.opacity = 0;
    grandmaSmoosh.style.left = `${gBB.left - grandmaSmooshWidth}px`;
    document.querySelector('#point-buff').style.opacity = 0;
    document.querySelector('#walk-buff').style.opacity = 0;
    isHundoStart = false;
    gameReady = false;
    walkReady = false;
    currentPointValue = 1;
    currentWalkSpeed = walkSpeeds.reg;
    currentWalkSound.pause();
    currentWalkSound = walk;
    document.querySelector('.game-over').style.display = 'flex';
    items.forEach((p) => {
        p.htmlItem.remove();
    });
    items = [];
    updateHighScore();
    window.clearInterval(specialItemInterval);
    specialItemInterval = null;
    document.querySelector('.g-bg-container').style.display = 'none';
    document.querySelector('.go-bg-container').style.display = 'block';
    document.querySelector('.moon0').style.display = 'none';
    document.querySelector('.moon1').style.display = 'block';
    document.querySelector('#rip-message').innerHTML = `RIP GRANDMA ${playerInfo.n}`;
    document.querySelector('.go-bg-container').insertBefore(sleigh, document.querySelector('.go-bg-m'));
};

const handleGameStart = (isHundo = false) => {
    firstTime = false;
    document.querySelector('.game-start').style.display = 'none';
    document.querySelector('.game-countdown').style.display = 'flex';
    runCountDown();
    initGrandma();
    if (isHundo) { initForHundoStart(); }
};

const handleGameRestart = (isHundo = false) => {
    grandmaSmoosh.style.opacity = 0;
    grandma.style.opacity = 1;
    grandmaLegs.style.opacity = 1;
    grandmaBag.style.opacity = 1;
    currentItemDropWait = maxItemDropWait;
    currentTreeDropWait = maxTreeDropWait;
    reindeerDropProbability = baseReindeerDropProbability;
    walkReady = true;
    document.querySelector('.game-over').style.display = 'none';
    document.querySelector('.game-countdown').style.display = 'flex';
    runCountDown();
    initGrandma();
    if (isHundo) { initForHundoStart(); }
};

const initGrandma = () => {
    if(zombieMode) {
        document.querySelector('.grandmas0').classList.add('zgrandmas0');
        document.querySelector('.grandmas1').classList.add('zgrandmas1');
        document.querySelector('.grandmal').classList.add('zgrandmal');
        document.querySelector('.grandmar').classList.add('zgrandmar');
        document.querySelector('.grandmac').classList.add('zgrandmac');
        document.querySelector('.grandmal0').classList.add('zgrandmal0');
        document.querySelector('.grandmal1').classList.add('zgrandmal1');
        document.querySelector('.grandmal2').classList.add('zgrandmal2');
    }
    else {
        document.querySelector('.grandmas0').classList.remove('zgrandmas0');
        document.querySelector('.grandmas1').classList.remove('zgrandmas1');
        document.querySelector('.grandmal').classList.remove('zgrandmal');
        document.querySelector('.grandmar').classList.remove('zgrandmar');
        document.querySelector('.grandmac').classList.remove('zgrandmac');
        document.querySelector('.grandmal0').classList.remove('zgrandmal0');
        document.querySelector('.grandmal1').classList.remove('zgrandmal1');
        document.querySelector('.grandmal2').classList.remove('zgrandmal2');
    }
}

const runCountDown = () => {
    document.querySelector('.g-bg-container').style.display = 'block';
    document.querySelector('.go-bg-container').style.display = 'none';
    document.querySelector('.moon0').style.display = 'block';
    document.querySelector('.moon1').style.display = 'none';
    document.querySelector('.g-bg-container').insertBefore(sleigh, document.querySelector('.g-bg-m'));
    var count = 3;
    document.querySelector('#countdown').innerHTML = count;
    countDownSound.play();
    gameCountDown = setInterval(() => {
        if (count == 1) {
            noggedUp = false;
            gameReady = true;
            document.querySelector('.game-countdown').style.display = 'none';
            updateScore(true);
            clearInterval(gameCountDown);
            specialItemInterval = window.setInterval(() => {
                reindeerFrame = (reindeerFrame + 1 == 3 ? 0 : reindeerFrame + 1);
                [...document.getElementsByClassName('reindeer0')].forEach(e => { e.style.display = reindeerFrame == 0 ? 'block' : 'none'; });
                [...document.getElementsByClassName('reindeer1')].forEach(e => { e.style.display = reindeerFrame == 1 ? 'block' : 'none'; });
                [...document.getElementsByClassName('reindeer2')].forEach(e => { e.style.display = reindeerFrame == 2 ? 'block' : 'none'; });
                flipPressie = flipPressie == 0 ? 1 : 0;
                [...document.getElementsByClassName('special-pressie')].forEach(e => {
                    e.children[0].style.display = flipPressie == 0 ? 'block' : 'none';
                    e.children[1].style.display = flipPressie == 1 ? 'block' : 'none';
                });
                [...document.getElementsByClassName('reindeer-r0')].forEach(e => { e.style.display = flipPressie == 0 ? 'block' : 'none'; });
                [...document.getElementsByClassName('reindeer-r1')].forEach(e => { e.style.display = flipPressie == 0 ? 'none' : 'block'; });
            }, 100);
        }
        else {
            count--;
            document.querySelector('#countdown').innerHTML = count;
            countDownSound.play();
        }
    }, 750);
};

const initForHundoStart = () => {
    isHundoStart = true;
    const floorVal = Math.floor(currentScore / reindeerDropProbabilityThreshold) - 1;
    const probMod = floorVal / 10;
    reindeerDropProbability = Math.min(Math.max(baseReindeerDropProbability, baseReindeerDropProbability + probMod), maxReindeerDropProbability);
};

const goAheadAndJump = () => {
    jumping = true;
    jump.play();
    currentWalkSound.pause();
    gsap.fromTo(document.querySelector('.grandma-container'), {y:0}, {y:-330, duration: 0.15, ease: 'power1.easeOut', onComplete: () => { 
        gsap.fromTo(document.querySelector('.grandma-container'), {y:-330}, {y:0, duration: 0.5, ease: 'power1.easeOut', onComplete: () => { 
            jumping = false;
            if(walkInterval){ currentWalkSound.play(); }
        }});
    }});
}

var noggedUp = false;
const grandmaTick = () => {
    if (grandma) { gBB = grandmaBag.getBoundingClientRect(); }
    if (shouldStop) {
        if (anim) {
            anim.kill();
            animLeg.kill();
            animBag.kill();
        }
        shouldStop = false;
    }
    else if (shouldGoLeft) {
        if (anim) {
            anim.kill();
            animLeg.kill();
            animBag.kill();
        }

        const dur = currentWalkSpeed * ((gBB.left + grandmaWidth - boardLeft) / (boardWidth));
        if (noggedUp) {
            anim = gsap.to(grandma, { x: boardWidth - grandmaWidth, duration: dur });
            animLeg = gsap.to(grandmaLegs, { x: boardWidth - grandmaWidth, duration: dur });
            animBag = gsap.to(grandmaBag, { x: boardWidth - grandmaWidth, duration: dur });
        }
        else {
            anim = gsap.to(grandma, { x: 0, duration: dur });
            animLeg = gsap.to(grandmaLegs, { x: 0, duration: dur });
            animBag = gsap.to(grandmaBag, { x: 0, duration: dur });
        }
        shouldGoLeft = false;
    }
    else if (shouldGoRight) {
        if (anim) {
            anim.kill();
            animLeg.kill();
            animBag.kill();
        }
        const dur = currentWalkSpeed * (1 - ((gBB.left - boardLeft) / (boardWidth)));
        if (noggedUp) {
            anim = gsap.to(grandma, { x: 0, duration: dur });
            animLeg = gsap.to(grandmaLegs, { x: 0, duration: dur });
            animBag = gsap.to(grandmaBag, { x: 0, duration: dur });
        }
        else {
            anim = gsap.to(grandma, { x: boardWidth - grandmaWidth, duration: dur });
            animLeg = gsap.to(grandmaLegs, { x: boardWidth - grandmaWidth, duration: dur });
            animBag = gsap.to(grandmaBag, { x: boardWidth - grandmaWidth, duration: dur });
        }
        shouldGoRight = false;
    }
    if (gameReady) {
        items.forEach((p) => {
            const cLoc = p.h ? parseFloat(window.getComputedStyle(p.htmlItem, null).getPropertyValue('left').replace('px', '')) : parseFloat(window.getComputedStyle(p.htmlItem, null).getPropertyValue('top').replace('px', ''));
            if(p.h) {
                p.htmlItem.style.left = p.r ? `${cLoc - p.fallSpeed}px` : `${cLoc + p.fallSpeed}px`;
            }
            else {
                p.htmlItem.style.top = `${cLoc + p.fallSpeed}px`;
            }
            if (areColliding(p.htmlItem.getBoundingClientRect(), gBB)) {
                if (p.htmlItem.classList.contains('reindeer') || p.htmlItem.classList.contains('reindeer-r')) {
                    handleGameOver();
                }
                else if (p.htmlItem.classList.contains('buff-double')) {
                    doublepoints.play();
                    currentPointValue = 2;
                    currentPointBuffDuration = 0;
                    document.querySelector('#point-buff').style.opacity = 1;
                }
                else if (p.htmlItem.classList.contains('buff-fast')) {
                    noggedUp = false;
                    currentWalkBuffDuration = 0;
                    currentWalkSpeed = walkSpeeds.fast;
                    speedup.play();
                    currentWalkSound = walkfast;
                    walk.pause();
                    walkslow.pause();
                    currentWalkSound.currentTime = 0;
                    if(!jumping) { currentWalkSound.play(); }
                    document.querySelector('#walk-buff').style.opacity = 1;
                    document.querySelector('#walk-buff-type').innerHTML = 'Speed Up!';
                }
                else if (p.htmlItem.classList.contains('buff-slow')) {
                    currentWalkBuffDuration = 0;
                    currentWalkSpeed = walkSpeeds.slow;
                    speeddown.play();
                    walk.pause();
                    walkfast.pause();
                    currentWalkSound = walkslow;
                    currentWalkSound.currentTime = 0;
                    if(!jumping) { currentWalkSound.play(); }
                    document.querySelector('#walk-buff').style.opacity = 1;
                    document.querySelector('#walk-buff-type').innerHTML = 'All Nogged Up!';
                    noggedUp = true;
                }
                else {
                    const sp = p.htmlItem.classList.contains('special-pressie');
                    updateScore(false, sp);
                    if(sp){
                        collectSpecial.currentTime = 0;
                        collectSpecial.play();
                    }
                    else {
                        collect.currentTime = 0;
                        collect.play();
                    }
                }
                removeItem(p);
            }
            else if ((!p.h && cLoc + p.fallSpeed > boardBottom) || (p.h && !p.r && cLoc + p.fallSpeed > boardWidth) || (p.h && p.r && cLoc + p.fallSpeed < -p.width)) {
                removeItem(p);
            }
            if (currentPointValue != 1) {
                currentPointBuffDuration++;
                document.querySelector('#point-buff-duration').style.width = `${64 * (1 - (currentPointBuffDuration / pointBuffDuration))}px`;
                if (currentPointBuffDuration >= pointBuffDuration) {
                    document.querySelector('#point-buff').style.opacity = 0;
                    currentPointValue = 1;
                }
            }
            if (currentWalkSound != walk) {
                currentWalkBuffDuration++;
                document.querySelector('#walk-buff-duration').style.width = `${64 * (1 - (currentWalkBuffDuration / walkBuffDuration))}px`;
                if (currentWalkBuffDuration >= walkBuffDuration) {
                    clearBuffs();
                    if (rightPressed || leftPressed) {
                        currentWalkSound.currentTime = 0;
                        if(!jumping) { currentWalkSound.play(); }
                    }
                }
            }
        });
        if (currentItemDropWait == maxItemDropWait) {
            handleShouldDropItem();
            currentItemDropWait = 0;
            maxItemDropWait = random(itemDropWait.min, itemDropWait.max)
        }
        else {
            currentItemDropWait++;
        }
        if (currentTreeDropWait == maxTreeDropWait) {
            if(Math.random() < 0.5) { handleShouldDropReindeer(); }
            else { handleShouldRunReindeer(); }
            currentTreeDropWait = 0;
            maxTreeDropWait = random(treeDropWait.min, treeDropWait.max)
        }
        else {
            currentTreeDropWait++;
        }
    }
    animateParallax();
    window.requestAnimationFrame(grandmaTick);
};

const clearBuffs = () => {
    noggedUp = false;
    document.querySelector('#walk-buff').style.opacity = 0;
    currentWalkSpeed = walkSpeeds.reg;
    walkfast.pause();
    walkslow.pause();
    currentWalkSound = walk;
}

const walkGrandma = () => {
    walk.pause();
    walkfast.pause();
    walkslow.pause();
    currentWalkSound.currentTime = 0;
    if(!jumping) { currentWalkSound.play(); }
    walkInterval = setInterval(() => {
        var lastWalkStep = walkStep;
        if (walkStep >= maxWalkSteps) { walkStep = 0; }
        else { walkStep++; }
        document.querySelector(`.grandmal${lastWalkStep}`).style.opacity = 0;
        document.querySelector(`.grandmal${walkStep}`).style.opacity = 1;
    }, 18 * currentWalkSpeed);
};

const stopGrandmaWalk = () => {
    currentWalkSound.pause();
    clearInterval(walkInterval);
    walkInterval = null;
    setTimeout(() => {
        for (var i = 0; i <= maxWalkSteps; i++) {
            document.querySelector(`.grandmal${i}`).style.opacity = (i == 0 ? 1 : 0);
        }
    }, 40);
}

const animateParallax = () => {
    const offset = grandma.getBoundingClientRect().left / boardWidth;
    gBgF.style.backgroundPosition = `${(0.5 + (offset * 0.1)) * 100}% 50%`;
    goBgF.style.backgroundPosition = `${(0.5 + (offset * 0.1)) * 100}% 50%`;
    gBgM.style.backgroundPosition = `${(0.5 + (offset * 0.25)) * 100}% 50%`;
    goBgM.style.backgroundPosition = `${(0.5 + (offset * 0.25)) * 100}% 50%`;
    gBgB.style.backgroundPosition = `${(0.5 + (offset * 0.45)) * 100}% 50%`;
    goBgB.style.backgroundPosition = `${(0.5 + (offset * 0.45)) * 100}% 50%`;
}